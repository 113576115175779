import { useMemo } from 'react';
import { IHistoryRecordExtended } from 'types';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useProbeDataFormatter } from 'app/shared/hooks';
import { BatchInfoType } from '../BatchInfo';
import { formatTimeWithDateIfNotToday } from 'app/shared/utils/formatTimeWithDate';

interface IInfoValue {
  title: string;
  content: {
    value: string;
    label: string;
    type: BatchInfoType;
    age?: string;
  }[];
}

const defaultDecimalPlaces = 3;
const hoursInDay = 24;

export const useBatchInfoValues = (batchHistory: IHistoryRecordExtended) => {
  const { t } = useTranslation();
  const { renderProbeData, formatProbeValue, getTranslatedUnit } =
    useProbeDataFormatter();

  const compressiveStrengthValue = useMemo(() => {
    return batchHistory?.compressiveStrengthMeasurements?.compressiveStrength?.filter(
      el => !el.isInvalid,
    );
  }, [batchHistory?.compressiveStrengthMeasurements?.compressiveStrength]);

  const values: IInfoValue[] = useMemo(() => {
    return [
      {
        title: `${t(translations.batchDetailsModal.truck)} #${
          batchHistory.vehicleId || '-'
        }`,
        content: [
          {
            value:
              batchHistory.customerName && batchHistory.customerId
                ? `${batchHistory.customerId} / ${batchHistory.customerName}`
                : '',
            label: t(translations.batchDetailsModal.clientName),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${batchHistory?.externalProjectName || ''}`,
            label: t(translations.batchDetailsModal.projectName),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${batchHistory?.originId || ''} / ${
              batchHistory?.originDescription || ''
            }`,
            label: t(translations.batchDetailsModal.plant),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${batchHistory.loadNumber || ''}`,
            label: t(translations.batchDetailsModal.load),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${formatProbeValue(
              batchHistory.cumulativeVolume,
            )}/${renderProbeData(batchHistory.totalOrdered)}`,
            label: t(translations.batchDetailsModal.volumeTotalOrder),
            type: BatchInfoType.VALUE,
          },
        ],
      },
      {
        title: t(translations.batchDetailsModal.mixDetails),
        content: [
          {
            value: `${batchHistory?.externalProductId || '-'}/${
              batchHistory?.externalProductName || '-'
            }`,
            label: t(translations.batchDetailsModal.mixId),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${renderProbeData(batchHistory.totalTicketed)}`,
            label: t(translations.batchDetailsModal.batchQuantity),
            type: BatchInfoType.VALUE,
          },
          {
            value:
              batchHistory.targetSlump.value && batchHistory.slumpRange.maxValue
                ? `${batchHistory.targetSlump.value} ± ${formatProbeValue(
                    batchHistory.slumpRange.maxValue -
                      batchHistory.targetSlump.value,
                    defaultDecimalPlaces,
                    'round',
                  )}`
                : '-',
            label: t(translations.batchDetailsModal.slump),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${renderProbeData(batchHistory.latestSlump)}`,
            label: t(translations.batchDetailsModal.slumpLatest),
            type: BatchInfoType.AGE,
            age: formatTimeWithDateIfNotToday(
              batchHistory.latestSlumpTimestampUtc,
            ),
          },
          {
            value: `${renderProbeData(batchHistory.latestTemperature)}`,
            label: t(translations.batchDetailsModal.temperatureLatest),
            type: BatchInfoType.AGE,
            age: formatTimeWithDateIfNotToday(
              batchHistory.latestTemperatureTimestampUtc,
            ),
          },
          {
            value: `${renderProbeData(batchHistory.airContent)}${
              typeof batchHistory.airContent === 'number' ? '%' : ''
            }`,
            label: t(translations.batchDetailsModal.airContentLatest),
            type: BatchInfoType.AGE,
            age: formatTimeWithDateIfNotToday(
              batchHistory.latestAirContentTimestampUtc,
            ),
          },
          {
            value: `${
              compressiveStrengthValue?.length
                ? compressiveStrengthValue[0]?.value
                : '-'
            } ${
              compressiveStrengthValue?.length
                ? getTranslatedUnit(
                    batchHistory?.compressiveStrengthMeasurements?.measurement,
                  )
                : ''
            } ${
              compressiveStrengthValue?.length
                ? `(${
                    compressiveStrengthValue[0].hoursAfterPourEvent >=
                    hoursInDay
                      ? `${
                          compressiveStrengthValue[0].hoursAfterPourEvent /
                          hoursInDay
                        } Days`
                      : `${compressiveStrengthValue[0].hoursAfterPourEvent} Hours`
                  })`
                : ''
            }`,
            label: t(translations.batchDetailsModal.compressiveStrength),
            type: BatchInfoType.BUTTONS,
          },
          {
            value: `${batchHistory.surfaceEvaporation.value || '-'} ${
              batchHistory.surfaceEvaporation.measurement || '-'
            }`,
            label: t(translations.batchDetailsModal.surfaceEvaporation),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${batchHistory.addedWaterCumulative.value || '-'} ${
              getTranslatedUnit(
                batchHistory.addedWaterCumulative.measurement,
              ) || '-'
            }`,
            label: t(translations.batchDetailsModal.addedWaterCumulative),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${batchHistory.waterCementRatio.value || '-'} ${
              batchHistory.waterCementRatio.measurement || '-'
            }`,
            label: t(translations.batchDetailsModal.waterCementRatio),
            type: BatchInfoType.VALUE,
          },
        ],
      },
      {
        title: t(translations.batchDetailsModal.weather),
        content: [
          {
            value: `${renderProbeData(batchHistory.weather?.temperature)}`,
            label: t(translations.batchDetailsModal.temperature),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${batchHistory.weather?.humidity?.value || '-'}%`,
            label: t(translations.batchDetailsModal.humidity),
            type: BatchInfoType.VALUE,
          },

          {
            value: `${renderProbeData(batchHistory.weather?.windSpeed)}`,
            label: t(translations.batchDetailsModal.windSpeed),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${renderProbeData(batchHistory.weather?.precipitation)}`,
            label: t(translations.batchDetailsModal.precipitation),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${batchHistory.weather?.eventStatusCode || '-'}`
              .replaceAll('_', ' ')
              .toLowerCase()
              .replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase()),
            label: t(translations.batchDetailsModal.measuredAt),
            type: BatchInfoType.VALUE,
            age: formatTimeWithDateIfNotToday(
              batchHistory.weather?.measuredAtUtc,
            ),
          },
          {
            value:
              batchHistory.city || batchHistory.state || batchHistory.country
                ? `${batchHistory.city ? `${batchHistory.city}, ` : ''}${
                    batchHistory.state ? `${batchHistory.state} ` : ''
                  }${batchHistory.country ? `${batchHistory.country}` : ''}`
                : '-',
            label: t(translations.batchDetailsModal.location),
            type: BatchInfoType.VALUE,
          },
        ],
      },
      {
        title: t(translations.batchDetailsModal.route),
        content: [
          {
            value: '',
            age: formatTimeWithDateIfNotToday(
              batchHistory.route?.departureOnUtc,
            ),
            label: t(translations.batchDetailsModal.departure),
            type: BatchInfoType.VALUE,
          },
          {
            value: '',
            age: formatTimeWithDateIfNotToday(batchHistory.route?.arriveOnUtc),
            label: t(translations.batchDetailsModal.arrival),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${renderProbeData(batchHistory.route?.travelTime)}`,
            label: t(translations.batchDetailsModal.travelTime),
            type: BatchInfoType.VALUE,
          },
          {
            value: `${renderProbeData(batchHistory.route?.travelDistance)}`,
            label: t(translations.batchDetailsModal.travelDistance),
            type: BatchInfoType.VALUE,
          },

          {
            value: `${renderProbeData(batchHistory.route?.trafficTime)}`,
            label: t(translations.batchDetailsModal.trafficTime),
            type: BatchInfoType.VALUE,
          },
        ],
      },
    ];
  }, [
    t,
    batchHistory.vehicleId,
    batchHistory.customerName,
    batchHistory.customerId,
    batchHistory?.externalProjectName,
    batchHistory?.originId,
    batchHistory?.originDescription,
    batchHistory.loadNumber,
    batchHistory.cumulativeVolume,
    batchHistory.totalOrdered,
    batchHistory?.externalProductId,
    batchHistory?.externalProductName,
    batchHistory.totalTicketed,
    batchHistory.targetSlump.value,
    batchHistory.slumpRange.maxValue,
    batchHistory.latestSlump,
    batchHistory.latestSlumpTimestampUtc,
    batchHistory.latestTemperature,
    batchHistory.latestTemperatureTimestampUtc,
    batchHistory.airContent,
    batchHistory.latestAirContentTimestampUtc,
    batchHistory?.compressiveStrengthMeasurements?.measurement,
    batchHistory.surfaceEvaporation.value,
    batchHistory.surfaceEvaporation.measurement,
    batchHistory.waterCementRatio.value,
    batchHistory.waterCementRatio.measurement,
    batchHistory.addedWaterCumulative.value,
    batchHistory.addedWaterCumulative.measurement,
    batchHistory.weather?.temperature,
    batchHistory.weather?.humidity?.value,
    batchHistory.weather?.windSpeed,
    batchHistory.weather?.precipitation,
    batchHistory.weather?.eventStatusCode,
    batchHistory.weather?.measuredAtUtc,
    batchHistory.city,
    batchHistory.state,
    batchHistory.country,
    batchHistory.route?.departureOnUtc,
    batchHistory.route?.arriveOnUtc,
    batchHistory.route?.travelTime,
    batchHistory.route?.travelDistance,
    batchHistory.route?.trafficTime,
    formatProbeValue,
    renderProbeData,
    compressiveStrengthValue,
    getTranslatedUnit,
  ]);

  return {
    batchInfoValues: values,
  };
};
