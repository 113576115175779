import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Link,
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { IOrganizationDetails, NotificationEntityType, Routes } from 'types';
import { Avatar } from '../../generic-ui/Avatar/Avatar';
import { useStyles } from './AppHeader.styles';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import { Button } from '../../generic-ui/Button/Button';
import { CustomTypography } from '../../generic-ui/Typography/Typography';
import { MenuItem } from '@material-ui/core';
import logo from 'assets/masteratlas-logo.png';
import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { getUserInitials } from 'app/shared/utils/getUserInitials';
import { useAuth } from 'app/shared/providers';

import { useProfileApi } from 'app/shared/hooks';
import { HamburgerNav } from './HamburgerNav/HamburgerNav';
import { firebaseAnalytics } from '../../../analytics';
import { themeLayout } from 'app/shared/theme';
import { actions } from 'app/store/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { TabIds } from 'app/shared/components/layout/TabNavigation/TabNavigation.types';
import { useOrganizationsApi } from 'app/shared/hooks/useOrganizationsApi';
import { useScreen } from 'app/shared/hooks/layout/useScreen';
import { resetLocalStorage } from 'app/shared/utils';
import { usePowerBIReportConfiguration } from 'app/shared/hooks/usePowerBIReportConfiguration';

export const AppHeader = () => {
  const { logout, supplierId, isSuperAdmin, isMember } = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const iconButtonRef = React.useRef<HTMLDivElement>(null);
  const { organizationId }: { organizationId: string } = useParams();
  const { profile } = useProfileApi({});
  const { data } = usePowerBIReportConfiguration({ organizationId });

  const [currentOrganization, setCurrentOrganization] = useState<
    IOrganizationDetails | undefined
  >();

  const currentOrganizationId = useMemo(() => {
    return organizationId === 'undefined' || !organizationId
      ? supplierId
      : organizationId;
  }, [organizationId, supplierId]);

  const queryParams = useMemo(() => {
    return {
      organizationId: currentOrganizationId,
    };
  }, [currentOrganizationId]);

  const { isMobile } = useScreen(themeLayout.breakpointBig);

  const [isOpen, setIsOpen] = useState(false);

  const { fetchOrganization } = useOrganizationsApi(queryParams);

  const handleToggle = () => {
    setIsOpen(prevOpen => !prevOpen);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsOpen(false);
    }
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleAllNotificationsClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'App header all notifications button');

    history.push(
      Routes.NotificationSettings.replace(
        ':organizationId/:tab/:page/:perPage/:alternativePage/:alternativePerPage',
        `${currentOrganizationId}/organization/1/50/1/50`,
      ),
    );
  }, [currentOrganizationId, history]);

  const handleAllBatchesClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'App header all batches button');

    const searchParams = new URLSearchParams({
      ['ticketId']: localStorage.getItem('ticketId') || '',
    });

    history.push({
      pathname: Routes.Batches.replace(
        ':organizationId/:page/:perPage',
        `${organizationId}/1/20`,
      ),
      search: `${searchParams}`,
    });
  }, [history, organizationId]);

  const handlePowerBIReportClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'App header power bi button');

    history.push({
      pathname: Routes.PowerBI.replace(':organizationId', `${organizationId}`),
    });
  }, [history, organizationId]);

  const handleTeammatesClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'App header teammates button');

    history.push(
      Routes.Teammates.replace(
        ':organizationId/:page/:perPage/:orderType',
        `${currentOrganizationId}/1/20/asc`,
      ),
    );
  }, [history, currentOrganizationId]);

  const handleProfileClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'App header profile button');

    history.push(
      Routes.MyProfile.replace(
        ':organizationId',
        location.pathname.includes('organizations/details') || !organizationId
          ? supplierId
          : organizationId,
      ),
    );
    handleClose();
  }, [history, location.pathname, supplierId, organizationId, handleClose]);

  const handleAllOrganizationsClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'App header all organizations button');

    resetLocalStorage();
    dispatch(actions.setNotificationEntitySectionDefaultState());
    history.push(Routes.Home);
  }, [dispatch, history]);

  const handleReportsClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'App header reports button');

    resetLocalStorage();
    dispatch(actions.setNotificationEntitySectionDefaultState());

    history.push(
      Routes.Reports.replace(
        ':organizationId/:tab',
        `${currentOrganizationId}/${TabIds.SUPPLIER_REPORT}`,
      ),
    );
  }, [currentOrganizationId, dispatch, history]);

  const handleLeaveOrganizationClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      '',
      'App header all organizations (leave) button',
    );

    resetLocalStorage();
    dispatch(actions.setNotificationEntitySectionDefaultState());
    history.push(Routes.Home);
    handleClose();
  }, [dispatch, history, handleClose]);

  const handleLogoutClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'App header logout button');
    localStorage.setItem('redirectUrl', '');

    resetLocalStorage();
    dispatch(actions.setNotificationEntitySectionDefaultState());
    logout();
    handleClose();
  }, [dispatch, logout, handleClose]);

  const handleMyOrganizationClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'App header my organization button');
    localStorage.setItem('PLANT_GROUP_ID', '');

    history.push(
      Routes.MyOrganization.replace(
        ':organizationId/:tab/:page/:perPage',
        `${supplierId}/${TabIds.ORGANIZATION_INFO}/1/20`,
      ),
    );

    resetLocalStorage();
    dispatch(actions.setNotificationEntitySectionDefaultState());
    handleClose();
  }, [dispatch, handleClose, history, supplierId]);

  const isOrganizationsRoutes = useMemo(() => {
    return (
      location.pathname.includes('organizations') ||
      location.pathname.includes('reports')
    );
  }, [location.pathname]);

  const isOrganizationButtonVisible = useMemo(() => {
    return (
      isSuperAdmin &&
      (isOrganizationsRoutes ||
        ((location.pathname.includes('profile') ||
          location.pathname.includes('contact-us')) &&
          organizationId === supplierId) ||
        location.pathname.includes('my-organization'))
    );
  }, [
    isOrganizationsRoutes,
    isSuperAdmin,
    location.pathname,
    organizationId,
    supplierId,
  ]);

  const isProjectsButtonVisible = useMemo(() => {
    return !isOrganizationButtonVisible;
  }, [isOrganizationButtonVisible]);

  const isNotificationsActive = useMemo(() => {
    return location.pathname.includes('notifications');
  }, [location.pathname]);

  const isBatchesActive = useMemo(() => {
    return location.pathname.includes('batches');
  }, [location.pathname]);

  const isPowerBIActive = useMemo(() => {
    return location.pathname.includes('power-bi');
  }, [location.pathname]);

  const isAllOrganizationsVisible = useMemo(() => {
    return isSuperAdmin && !isOrganizationButtonVisible;
  }, [isOrganizationButtonVisible, isSuperAdmin]);

  const isTeammatesActive = useMemo(() => {
    return location.pathname.includes('teammates');
  }, [location.pathname]);

  const isOrganizationsActive = useMemo(() => {
    return (
      location.pathname.includes('organizations') &&
      !location.pathname.includes('details') &&
      !location.pathname.includes('add') &&
      !location.pathname.includes('edit') &&
      !location.pathname.includes('my-organization')
    );
  }, [location.pathname]);

  const isReportsActive = useMemo(() => {
    return location.pathname.includes('reports');
  }, [location.pathname]);

  useEffect(() => {
    if (
      !location.pathname.includes('details') &&
      !location.pathname.includes('edit') &&
      !location.pathname.includes('my-organization')
    ) {
      fetchOrganization().then(res => {
        if (res?.data) setCurrentOrganization(res.data.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOrganization, queryParams]);

  return (
    <div className={classes.appHeader}>
      <div>
        <Link
          to={
            isProjectsButtonVisible
              ? generatePath(Routes.NotificationEntityPage, {
                  organizationId: currentOrganizationId,
                  entityType: NotificationEntityType.PROJECT,
                })
              : Routes.Home
          }
          data-testid="policy-link"
        >
          <img
            src={logo}
            data-testid="logo"
            alt="logo"
            className={classes.clientLogo}
          />
        </Link>
      </div>

      {currentOrganization?.supplierImagePath && (
        <div>
          <img
            src={currentOrganization?.supplierImagePath}
            data-testid="logo"
            alt="logo"
            className={classes.clientLogo}
          />
        </div>
      )}

      {!isMobile ? (
        <div className={classes.buttonsContainer}>
          <>
            {isOrganizationButtonVisible && (
              <>
                <Button
                  variant={isOrganizationsActive ? 'headerActive' : 'header'}
                  active={isOrganizationsActive}
                  onClick={handleAllOrganizationsClick}
                  icon="projects"
                >
                  <CustomTypography
                    variant="buttonText"
                    bold={isOrganizationsActive}
                    color={isOrganizationsActive ? 'accentDark' : 'greyscale1'}
                  >
                    {t(translations.header.allOrganizations)}
                  </CustomTypography>
                </Button>

                <Button
                  variant={isReportsActive ? 'headerActive' : 'header'}
                  active={isReportsActive}
                  onClick={handleReportsClick}
                  icon="reports"
                >
                  <CustomTypography
                    variant="buttonText"
                    bold={isReportsActive}
                    color={isReportsActive ? 'accentDark' : 'greyscale1'}
                  >
                    {t(translations.header.reports)}
                  </CustomTypography>
                </Button>
              </>
            )}

            {isProjectsButtonVisible && (
              <>
                {!!data?.hasReport && !isMember && (
                  <Button
                    variant={isPowerBIActive ? 'headerActive' : 'header'}
                    active={isPowerBIActive}
                    onClick={handlePowerBIReportClick}
                    icon="reports"
                  >
                    <CustomTypography
                      variant="buttonText"
                      bold={isPowerBIActive}
                      color={isPowerBIActive ? 'accentDark' : 'greyscale1'}
                    >
                      {t(translations.header.powerBI)}
                    </CustomTypography>
                  </Button>
                )}
                <Button
                  variant={isNotificationsActive ? 'headerActive' : 'header'}
                  active={isNotificationsActive}
                  onClick={handleAllNotificationsClick}
                  icon="allNotifications"
                >
                  <CustomTypography
                    variant="buttonText"
                    bold={isNotificationsActive}
                    color={isNotificationsActive ? 'accentDark' : 'greyscale1'}
                  >
                    {t(translations.header.allNotifications)}
                  </CustomTypography>
                </Button>

                <Button
                  variant={isBatchesActive ? 'headerActive' : 'header'}
                  active={isBatchesActive}
                  onClick={handleAllBatchesClick}
                  icon="batches"
                >
                  <CustomTypography
                    variant="buttonText"
                    bold={isBatchesActive}
                    color={isBatchesActive ? 'accentDark' : 'greyscale1'}
                  >
                    {t(translations.header.batches)}
                  </CustomTypography>
                </Button>

                {!isMember && (
                  <Button
                    variant={isTeammatesActive ? 'headerActive' : 'header'}
                    active={isTeammatesActive}
                    onClick={handleTeammatesClick}
                    icon="teammates"
                  >
                    <CustomTypography
                      variant="buttonText"
                      bold={isTeammatesActive}
                      color={isTeammatesActive ? 'accentDark' : 'greyscale1'}
                    >
                      {t(translations.header.teammates)}
                    </CustomTypography>
                  </Button>
                )}
              </>
            )}
          </>

          <span className={classes.divider} />
          <div ref={iconButtonRef} className={classes.avatarContainer}>
            <Avatar
              data-testid="avatar"
              content={getUserInitials(profile?.firstName, profile?.lastName)}
              size={32}
              onClick={handleToggle}
            />
          </div>

          <Popper
            open={isOpen}
            anchorEl={iconButtonRef.current}
            transition
            disablePortal
            placement="bottom-end"
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={isOpen}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      className={classes.menuList}
                    >
                      <MenuItem onClick={handleProfileClick}>
                        <CustomTypography
                          variant="bodySmall"
                          color="accentDark"
                        >
                          {t(translations.header.myProfile)}
                        </CustomTypography>
                      </MenuItem>

                      {!isMember && (
                        <MenuItem onClick={handleMyOrganizationClick}>
                          <CustomTypography
                            variant="bodySmall"
                            color="accentDark"
                          >
                            {t(translations.header.myOrganization)}
                          </CustomTypography>
                        </MenuItem>
                      )}

                      {isAllOrganizationsVisible && (
                        <MenuItem onClick={handleLeaveOrganizationClick}>
                          <CustomTypography
                            variant="bodySmall"
                            color="greyscale2"
                          >
                            {t(translations.header.allOrganizations)}
                          </CustomTypography>
                        </MenuItem>
                      )}
                      <MenuItem onClick={handleLogoutClick}>
                        <CustomTypography
                          variant="bodySmall"
                          color="greyscale2"
                        >
                          {t(translations.header.logOut)}
                        </CustomTypography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      ) : (
        <HamburgerNav
          isOrganizationButtonVisible={isOrganizationButtonVisible}
          isProjectsButtonVisible={isProjectsButtonVisible}
          isTeammatesActive={isTeammatesActive}
          isOrganizationsActive={isOrganizationsActive}
          isBatchesActive={isBatchesActive}
          handleAllOrganizationsClick={handleAllOrganizationsClick}
          handleTeammatesClick={handleTeammatesClick}
          handleProfileClick={handleProfileClick}
          handleLeaveOrganizationClick={handleLeaveOrganizationClick}
          handleMyOrganizationClick={handleMyOrganizationClick}
          handleLogoutClick={handleLogoutClick}
          handleBatchesClick={handleAllBatchesClick}
          isLeaveOrganizationVisible={isAllOrganizationsVisible}
          isMember={isMember}
          isReportsActive={isReportsActive}
          handleReportsClick={handleReportsClick}
          isNotificationsActive={isNotificationsActive}
          handleAllNotificationsClick={handleAllNotificationsClick}
          isPowerBIActive={isPowerBIActive}
          handlePowerBIReportClick={handlePowerBIReportClick}
          isPowerBIConfigured={!!data?.hasReport && !isMember}
        />
      )}
    </div>
  );
};
