import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { firebaseAnalytics } from 'app/shared/analytics';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { useCallback } from 'react';
import { Routes, IHistoryRecord, IMetaData } from 'types';
import { useStyles } from './FiltersBlock.styles';
import { BatchesFilterBlock } from './FilterBlock';
import { TicketFilterBlock } from './TicketFilterBlock';

interface IFilterBlockProps {
  withStatusesOnly: boolean;
  isUserAssignedEntities: boolean;
  onChangeTableData: (data?: IHistoryRecord[], metadata?: IMetaData) => void;
  loadingHandler: (value: boolean) => void;
  refreshHandler: () => void;
  refresh: boolean;
  toJobRemaining: boolean;
  predictions: boolean;
}

export const FiltersBlock = ({
  withStatusesOnly,
  onChangeTableData,
  isUserAssignedEntities,
  loadingHandler,
  refresh,
  toJobRemaining,
  predictions,
}: IFilterBlockProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSpoilerClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.Batches,
      'Batches filter section spoiler',
    );
  }, []);

  return (
    <div>
      <div className={classes.wrapper}>
        <SectionSpoiler
          header={'Filter'.toUpperCase()}
          withBackground
          handleSpoilerClick={handleSpoilerClick}
        >
          <BatchesFilterBlock
            refresh={refresh}
            batchesHistoryHandler={onChangeTableData}
            withStatusesOnly={withStatusesOnly}
            loadingHandler={loadingHandler}
            isUserAssignedEntities={isUserAssignedEntities}
            toJobRemaining={toJobRemaining}
            predictions={predictions}
          />
        </SectionSpoiler>
      </div>

      <div className={classes.wrapper}>
        <SectionSpoiler
          header={t(translations.filterBlock.ticketId).toUpperCase()}
          withBackground
          handleSpoilerClick={handleSpoilerClick}
        >
          <div className={classes.filterWrapper}>
            <TicketFilterBlock
              refresh={refresh}
              withStatusesOnly={withStatusesOnly}
              ticketsHistoryHandler={onChangeTableData}
              loadingHandler={loadingHandler}
              toJobRemaining={toJobRemaining}
              predictions={predictions}
            />
          </div>
        </SectionSpoiler>
      </div>
    </div>
  );
};
