import { useQuery } from 'react-query';
import { IError, IPowerBIConfigurations } from 'types';
import { useAuthClient } from './useAuthClient';
import { useError } from '../providers';
import { useMemo } from 'react';

const getPowerBIReportConfiguration = 'getPowerBIReportConfiguration';

interface IProps {
  organizationId: string;
}

export function usePowerBIReportConfiguration({ organizationId }: IProps) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const getPowerBIReportParams = useMemo(() => {
    return `/api/v1/${organizationId}/PowerBiEmbed/HasReportConfigured`;
  }, [organizationId]);

  return useQuery<IPowerBIConfigurations, IError>(
    [getPowerBIReportConfiguration, organizationId],
    async () => {
      return await authClient(`${getPowerBIReportParams}`, {
        method: 'GET',
      });
    },
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      onError: error => {
        openModal(error);
      },
    },
  );
}
