import { useCallback, useMemo, useEffect, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './OrganizationMixIdTable.styles';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { Routes } from 'types';
import { useParams } from 'react-router-dom';
import { useMixDesignApi } from 'app/shared/hooks';
import { useMixIdList } from './hooks';
import { Checkbox, ClickAwayListener, Popover } from '@material-ui/core';
import { TextInput } from 'app/shared/components/generic-ui/TextInput/TextInput';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { ColumnSections, useMixIdColumns } from './hooks/useMixIdColumns';
import { themeColors } from 'app/shared/theme/theme';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { useSearchHelper } from 'app/shared/utils';
import { MixIdSearch } from './MixIdSearch';
import { useModal } from 'app/shared/providers';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { ReactComponent as RadioIcon } from 'assets/radio_icon.svg';
import { ReactComponent as RadioEmpty } from 'assets/radio_empty.svg';

const skipOnePage = 1;
const even = 2;
const reminder = 0;
const clicksInitialState = 0;
const singleItem = 1;

export const OrganizationMixIdTable = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    columns,
    changeColumnsHandler,
    selectedColumnSections,
    columnSections,
  } = useMixIdColumns();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [errorText, setErrorText] = useState('');
  const { searchQuery, prepareSearchQueryParameter } = useSearchHelper();
  const { setTitle, setContent, openModal, closeModalHandler } = useModal();
  const [clickerCount, setClickerCount] = useState(clicksInitialState);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      id: string,
      hasError: boolean,
    ) => {
      if (hasError) {
        setErrorText(
          id === 'hesAge'
            ? t(translations.mixIdTable.errorHesAge)
            : id === 'maxWaterCementRatio'
            ? t(translations.mixIdTable.errorWCRatioAge)
            : t(translations.mixIdTable.error),
        );
      }
      setAnchorEl(event.currentTarget);
    },
    [t],
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const {
    page,
    perPage,
    tab,
    organizationId,
  }: {
    page: string;
    perPage: string;
    orderType: string;
    organizationId: string;
    tab: string;
  } = useParams();

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        query: prepareSearchQueryParameter(searchQuery),
        take: perPage,
        skip: `${(+page - skipOnePage) * +perPage}`,
        sortBy: 'externalId',
        sortAsc: true,
      },
    };
  }, [organizationId, page, perPage, prepareSearchQueryParameter, searchQuery]);

  const {
    fetchMixIdCategorizations,
    mixIdCategorization,
    isFetchingMixIdCategorizations,
    mixIdCategorizationTotalCount,
    updateMixDesignCategorization,
    isUpdatingMixDesignCategorization,
  } = useMixDesignApi(queryParams);

  useEffect(() => {
    fetchMixIdCategorizations();
  }, [fetchMixIdCategorizations, queryParams]);

  const {
    mixIdList,
    updatedMixIdList,
    textboxValues,
    error,
    changeValueHandler,
    saveMixIdsChanges,
    cancelMixIdsChanges,
    validateField,
  } = useMixIdList({
    mixIdList: mixIdCategorization,
  });

  const modalContent = useMemo(() => {
    return (
      <div className={classes.modalContent}>
        <CustomTypography variant="bodyLarge" color="accentLight">
          {t(translations.mixIdTable.modalText)}
        </CustomTypography>

        <div className={classes.modalFooter}>
          <Button variant="primarySave" onClick={closeModalHandler}>
            <CustomTypography variant="buttonText" bold color="white">
              {t(translations.mixIdTable.ok)}
            </CustomTypography>
          </Button>
        </div>
      </div>
    );
  }, [classes.modalContent, classes.modalFooter, closeModalHandler, t]);

  const renderCell = useCallback(
    (rowData: any, cellId: string, isHovered?: boolean) => {
      if (cellId === 'mixId') {
        return (
          <div className={isHovered ? classes.mixIdHovered : classes.mixId}>{`${
            rowData.externalProductId || ''
          } / ${rowData.externalProductName || ''}`}</div>
        );
      } else if (textboxValues.includes(cellId)) {
        const hasError = validateField(rowData[cellId], cellId);

        return (
          <div className={classes.errorContainer}>
            {hasError ? (
              <div
                className={classes.info}
                onMouseEnter={e =>
                  handlePopoverOpen(e, cellId, rowData[cellId])
                }
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon
                  height={16}
                  width={16}
                  stroke={themeColors.systemRed}
                />
              </div>
            ) : null}

            <TextInput
              name={cellId}
              currentValue={rowData[cellId] === null ? '' : rowData[cellId]}
              error={!!hasError}
              maxLength={6}
              onChangeHandler={e => {
                changeValueHandler(e?.target?.value || '', cellId, rowData);
              }}
              backgroundColor="white"
              withHelperText={false}
            />
          </div>
        );
      } else {
        return (
          <Checkbox
            disableRipple
            size="small"
            classes={{
              colorSecondary: classes.icon,
              checked: classes.checkedIcon,
              disabled: classes.disabled,
            }}
            onChange={e => {
              changeValueHandler(e.target.checked, cellId, rowData);
            }}
            checked={rowData[cellId] === true ?? false}
          />
        );
      }
    },
    [
      changeValueHandler,
      classes.checkedIcon,
      classes.disabled,
      classes.errorContainer,
      classes.icon,
      classes.info,
      classes.mixId,
      classes.mixIdHovered,
      handlePopoverClose,
      handlePopoverOpen,
      textboxValues,
      validateField,
    ],
  );

  const saveHandler = useCallback(() => {
    updateMixDesignCategorization(updatedMixIdList);
    saveMixIdsChanges();
  }, [saveMixIdsChanges, updateMixDesignCategorization, updatedMixIdList]);

  const openModalHandler = useCallback(() => {
    setTitle(t(translations.mixIdTable.warning));
    setContent(modalContent);
    openModal(true);
  }, [modalContent, openModal, setContent, setTitle, t]);

  const paginationChangeHandler: () => Promise<boolean> = useCallback(() => {
    if (updatedMixIdList.length) {
      setClickerCount(prevState => ++prevState);
      openModalHandler();
    }

    return new Promise(resolve => {
      resolve(updatedMixIdList.length ? false : true);
    });
  }, [openModalHandler, updatedMixIdList.length]);

  const clickAwayHandler = useCallback(() => {
    if (updatedMixIdList.length) {
      setClickerCount(prevState => ++prevState);

      if (clickerCount % even === reminder) {
        openModalHandler();
      }
    }
  }, [updatedMixIdList.length, clickerCount, openModalHandler]);

  const checkbox = useCallback(
    (label: ColumnSections) => {
      return (
        <div className={classes.checkbox} key={label}>
          <Checkbox
            disabled={
              selectedColumnSections.length === singleItem &&
              !!selectedColumnSections?.find(section => section.id === label)
            }
            checkedIcon={<RadioIcon />}
            icon={<RadioEmpty />}
            onChange={() => {
              changeColumnsHandler(label);
            }}
            checked={
              !!selectedColumnSections?.find(section => section.id === label)
            }
          />
          <CustomTypography variant="bodySmall" color="accentDark">
            {label}
          </CustomTypography>
        </div>
      );
    },
    [changeColumnsHandler, classes.checkbox, selectedColumnSections],
  );

  return (
    <div className={classes.wrapper}>
      <Popover
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.errorText}>
          <CustomTypography variant="buttonTextSmall" color="systemRed">
            {errorText}
          </CustomTypography>
        </div>
      </Popover>

      <MixIdSearch />

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={clickAwayHandler}
      >
        <div>
          {updatedMixIdList.length ? (
            <div className={classes.buttons}>
              <div className={classes.cancel}>
                <Button
                  onClick={cancelMixIdsChanges}
                  height={30}
                  variant={'primaryOutlined'}
                >
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color="accentDark"
                  >
                    {t(translations.mixIdTable.cancel)}
                  </CustomTypography>
                </Button>
              </div>

              <div className={classes.save}>
                <Button
                  disabled={error}
                  onClick={saveHandler}
                  height={30}
                  variant={'secondarySave'}
                >
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color="white"
                  >
                    {t(translations.mixIdTable.saveChanges)}
                  </CustomTypography>
                </Button>
              </div>
            </div>
          ) : null}
          <div className={classes.columnsSections}>
            {columnSections.map(section => checkbox(section.id))}
          </div>
          <div className={classes.tableWrapper}>
            <Table
              withHover={true}
              withPerPageCount={true}
              withPagination={true}
              columns={columns}
              tableData={mixIdList}
              withCheckbox={false}
              isLoading={
                isFetchingMixIdCategorizations ||
                isUpdatingMixDesignCategorization
              }
              emptyTableText={`${t(translations.mixIdTable.noMixIds)}.`}
              baseURL={Routes.OrganizationDetails.replace(
                ':organizationId/:tab',
                `${organizationId}/${tab}`,
              )}
              rowsTotal={mixIdCategorizationTotalCount}
              renderCell={renderCell}
              paginationChangeHandler={paginationChangeHandler}
              isScrollable={true}
            />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
