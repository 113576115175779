import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { ProjectStatusLabel } from 'app/shared/components/generic-ui/ProjectStatusLabel/ProjectStatusLabel';
import { useStyles } from './PourEventDetails.styles';
import arrow_left from 'assets/arrow_left.svg';
import { MixPropsSection } from './MixPropsSection/MixPropsSection';
import {
  ModalType,
  PourEventHistorySection,
} from './PourEventHistorySection/PourEventHistorySection';
import {
  ActionButton,
  ActionButtonStyle,
  IAction,
} from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import { IPaginationUrlParams } from 'app/shared/components/generic-ui/Table/Table.types';
import { BatchDetailsModal } from './BatchDetailsModal/BatchDetailsModal';
import { useBatchApi, usePourEventsApi } from 'app/shared/hooks';
import { EscapeAction, escapePercentSign } from 'app/shared/utils';
import { firebaseAnalytics } from 'app/shared/analytics';
import { Routes } from 'types';
import { RenameModal } from 'app/shared/components/generic-ui/RenameModal/RenameModal';
import { useAuth } from 'app/shared/providers';

const skipOnePage = 1;
const defaultRows = 0;
const defaultMixIdIndex = 1;
const dif = 2;

export const PourEventDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMember } = useAuth();
  const status = new URLSearchParams(location.search).get('status');
  const crn = new URLSearchParams(location.search).get('crn');

  const {
    pourEventId,
    id,
    organizationId,
    name,
    externalProductId,
  }: {
    pourEventId: string;
    id: string;
    organizationId: string;
    name: string;
    externalProductId: string;
  } = useParams();
  const { page, perPage }: IPaginationUrlParams = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBatchDetailsOpen, setIsBatchDetailsOpen] = useState(!!crn);
  const [modalType, setModalType] = useState<ModalType | undefined>();
  const history = useHistory();
  const pourEventNameDecoded = useMemo(() => {
    return escapePercentSign(EscapeAction.DECODE, decodeURIComponent(name));
  }, [name]);
  const [currentName, setCurrentName] = useState(pourEventNameDecoded);
  const [toJobRemaining, setToJobRemaining] = useState(false);
  const [predictions, setPredictions] = useState(false);

  const pourEventIdDecoded = useMemo(() => {
    return decodeURIComponent(pourEventId);
  }, [pourEventId]);

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        projectId: id,
        pourEventGID: pourEventId,
        externalProductId: externalProductId,
        take: perPage,
        skip: `${(+page - skipOnePage) * +perPage}`,
        ToJobRemaining: toJobRemaining,
        Predictions: predictions,
      },
    };
  }, [
    externalProductId,
    id,
    organizationId,
    page,
    perPage,
    pourEventId,
    toJobRemaining,
    predictions,
  ]);

  const mixQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        request: pourEventId,
      },
    };
  }, [organizationId, pourEventId]);

  const {
    fetchPourHistory,
    pourEventHistory,
    isFetchingPourEventHistory,
    pourEventHistoryResponse,
  } = usePourEventsApi(queryParams);

  const { mutatePourEvent } = usePourEventsApi({
    organizationId: organizationId,
  });

  const { fetchMapHeader, mapHeader, isFetchingMapHeader } = useBatchApi({
    organizationId: organizationId,
  });

  const {
    fetchPourEventMixDesign,
    pourEventMixDesign,
    isFetchingPourEventMixDesign,
  } = usePourEventsApi(mixQueryParams);

  useEffect(() => {
    fetchPourHistory();
  }, [fetchPourHistory, queryParams]);

  useEffect(() => {
    fetchPourEventMixDesign();
  }, [fetchPourEventMixDesign]);

  useEffect(() => {
    fetchMapHeader();
  }, [fetchMapHeader]);

  const actions: IAction[] = [
    {
      label: t(translations.pourEventPage.renamePourEvent),
      onClick: () => {
        firebaseAnalytics.logPressEvent(
          Routes.SinglePourEvent,
          'Rename pour event action',
        );

        setIsModalOpen(true);
      },
    },
  ];

  const currentMixDesign = useMemo(() => {
    return pourEventMixDesign?.find(
      el => el.mixDesign.externalProductId === externalProductId,
    );
  }, [externalProductId, pourEventMixDesign]);

  const currentMixDesignPosition = useMemo(() => {
    return currentMixDesign && pourEventMixDesign
      ? pourEventMixDesign.indexOf(currentMixDesign) + defaultMixIdIndex
      : defaultMixIdIndex;
  }, [currentMixDesign, pourEventMixDesign]);

  const handleBackClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(Routes.SinglePourEvent, 'Back icon');

    history.goBack();
  }, [history]);

  const handleActionGroupClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.SinglePourEvent,
      'Actions group element',
    );
  }, []);

  const pushUrlHandler = useCallback(
    (params: URLSearchParams, mixId?: string) => {
      return history.push({
        pathname: Routes.SinglePourEvent.replace(
          ':organizationId/:id/:pourEventId/:externalProductId/:name/:page/:perPage',
          `${organizationId}/${id}/${pourEventId}/${
            mixId || externalProductId
          }/${name}/1/20`,
        ),
        search: `${params}`,
      });
    },
    [externalProductId, history, id, name, organizationId, pourEventId],
  );

  const handleHistoryRecordClick = useCallback(
    (crn: string, modalType?: ModalType) => {
      firebaseAnalytics.logPressEvent(
        Routes.SinglePourEvent,
        'History record row',
      );

      const statusParam = new URLSearchParams({
        ['status']: status || '',
        ['crn']: crn,
      });

      pushUrlHandler(statusParam);

      setModalType(modalType);
      setIsBatchDetailsOpen(true);
    },
    [pushUrlHandler, status],
  );

  const renamePourEvent = useCallback(
    (data: any) => {
      firebaseAnalytics.logPressEvent(
        Routes.SinglePourEvent,
        'Rename pour event modal save button',
      );

      mutatePourEvent({
        pourEventGid: pourEventIdDecoded,
        newName: data.name,
      });

      setCurrentName(data.name);
      setIsModalOpen(false);
    },
    [mutatePourEvent, pourEventIdDecoded],
  );

  const handleRefresh = useCallback(() => {
    fetchPourHistory();
  }, [fetchPourHistory]);

  const closeModalHandler = useCallback(() => {
    setIsBatchDetailsOpen(false);
    const statusParam = new URLSearchParams({
      ['status']: status || '',
      ['crn']: '',
    });

    pushUrlHandler(statusParam);

    fetchPourHistory();
  }, [fetchPourHistory, pushUrlHandler, status]);

  const getPrevMixDesignHandler = useCallback(() => {
    if (currentMixDesignPosition > defaultMixIdIndex && pourEventMixDesign) {
      const mixId =
        pourEventMixDesign[currentMixDesignPosition - dif].mixDesign
          .externalProductId;

      const statusParam = new URLSearchParams({
        ['status']: status || '',
      });

      pushUrlHandler(statusParam, mixId);
    }
  }, [currentMixDesignPosition, pourEventMixDesign, pushUrlHandler, status]);

  const getNextMixDesignHandler = useCallback(() => {
    if (
      pourEventMixDesign &&
      currentMixDesignPosition < pourEventMixDesign?.length
    ) {
      const mixId =
        pourEventMixDesign[currentMixDesignPosition].mixDesign
          .externalProductId;

      const statusParam = new URLSearchParams({
        ['status']: status || '',
      });

      pushUrlHandler(statusParam, mixId);
    }
  }, [currentMixDesignPosition, pourEventMixDesign, pushUrlHandler, status]);

  return (
    <div className={classes.wrapper}>
      {crn && (
        <BatchDetailsModal
          isOpen={isBatchDetailsOpen}
          crn={crn}
          closeHandler={closeModalHandler}
          modalType={modalType}
        />
      )}

      <RenameModal
        isOpen={isModalOpen}
        name={pourEventNameDecoded}
        closeHandler={() => {
          setIsModalOpen(false);
        }}
        header={t(translations.renamePourEventModal.renamePourEvent)}
        placeholder={t(translations.renamePourEventModal.pourEventName)}
        label={t(translations.renamePourEventModal.pourEventName)}
        onSubmit={renamePourEvent}
      />

      {pourEventNameDecoded ? (
        <div className={classes.container}>
          <div className={classes.pourEventHeaderContainer}>
            <img
              src={arrow_left}
              className={classes.backArrow}
              onClick={handleBackClick}
            />
            <CustomTypography variant="header3" bold color="accentDark">
              {currentName || ''}
            </CustomTypography>

            {status ? (
              <div className={classes.pourEventStatus}>
                <ProjectStatusLabel status={status} />
              </div>
            ) : null}
          </div>

          {!isMember && (
            <ActionButton
              style={ActionButtonStyle.DARK}
              options={actions}
              actionsGroupClickHandler={handleActionGroupClick}
            />
          )}
        </div>
      ) : null}

      <MixPropsSection
        mixProps={currentMixDesign}
        isLoading={isFetchingPourEventMixDesign}
        totalCount={pourEventMixDesign?.length}
        selectedPosition={currentMixDesignPosition}
        getPrevMixDesignHandler={getPrevMixDesignHandler}
        getNextMixDesignHandler={getNextMixDesignHandler}
      />

      {!isFetchingMapHeader && mapHeader && (
        <PourEventHistorySection
          pourEventHistory={pourEventHistory?.pourEventHistoryDetails}
          isLoading={isFetchingPourEventHistory}
          name={name}
          rowsTotal={
            pourEventHistoryResponse?.metadata?.totalCount || defaultRows
          }
          onClick={handleHistoryRecordClick}
          probeStatuses={mapHeader}
          onRefresh={handleRefresh}
          setToJobRemaining={setToJobRemaining}
          setPredictions={setPredictions}
        />
      )}
    </div>
  );
};
