import React, { useState } from 'react';
import { useStyles } from './HamburgerNav.styles';
import menu from 'assets/menu.png';
import close from 'assets/close_icon.png';
import { Collapse } from '@material-ui/core';
import { useScreen } from 'app/shared/hooks';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Button } from 'app/shared/components/generic-ui/Button/Button';

interface IHamburgerNavProps {
  isOrganizationButtonVisible: boolean;
  isProjectsButtonVisible: boolean;
  isNotificationsActive: boolean;
  isBatchesActive: boolean;
  isTeammatesActive: boolean;
  isOrganizationsActive: boolean;
  isLeaveOrganizationVisible: boolean;
  isMember: boolean;
  isReportsActive: boolean;
  isPowerBIActive: boolean;
  handleAllNotificationsClick: () => void;
  handleAllOrganizationsClick: () => void;
  handleTeammatesClick: () => void;
  handleProfileClick: () => void;
  handleBatchesClick: () => void;
  handleLeaveOrganizationClick: () => void;
  handleMyOrganizationClick: () => void;
  handleLogoutClick: () => void;
  handleReportsClick: () => void;
  handlePowerBIReportClick: () => void;
}

export const HamburgerNav = ({
  isOrganizationButtonVisible,
  isProjectsButtonVisible,
  isTeammatesActive,
  isOrganizationsActive,
  isLeaveOrganizationVisible,
  isNotificationsActive,
  isBatchesActive,
  handleBatchesClick,
  handleAllOrganizationsClick,
  handleTeammatesClick,
  handleProfileClick,
  handleMyOrganizationClick,
  handleLeaveOrganizationClick,
  handleAllNotificationsClick,
  handleLogoutClick,
  isMember,
  isReportsActive,
  handleReportsClick,
  isPowerBIActive,
  handlePowerBIReportClick,
}: IHamburgerNavProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { screenHeight } = useScreen();

  return (
    <div>
      <div onClick={() => setIsOpen(!isOpen)}>
        {!isOpen ? <img src={menu} /> : <img src={close} />}
      </div>
      <Collapse in={isOpen}>
        <div style={{ height: screenHeight }} className={classes.menuContainer}>
          <div className={classes.sectionHeader}>
            <CustomTypography variant="bodyRegular" bold color="accentDark">
              {t(translations.header.menu)}
            </CustomTypography>
          </div>

          <div className={classes.sectionItems}>
            {isOrganizationButtonVisible && (
              <div className={classes.buttonContainer}>
                <Button
                  variant={isOrganizationsActive ? 'headerActive' : 'header'}
                  active={isOrganizationsActive}
                  onClick={handleAllOrganizationsClick}
                  icon="projects"
                >
                  <CustomTypography
                    variant="buttonText"
                    bold={isOrganizationsActive}
                    color={isOrganizationsActive ? 'accentDark' : 'greyscale1'}
                  >
                    {t(translations.header.allOrganizations)}
                  </CustomTypography>
                </Button>

                <Button
                  variant={isReportsActive ? 'headerActive' : 'header'}
                  active={isReportsActive}
                  onClick={handleReportsClick}
                  icon="reports"
                >
                  <CustomTypography
                    variant="buttonText"
                    bold={isReportsActive}
                    color={isReportsActive ? 'accentDark' : 'greyscale1'}
                  >
                    {t(translations.header.reports)}
                  </CustomTypography>
                </Button>
              </div>
            )}

            {isProjectsButtonVisible && (
              <>
                <div className={classes.buttonContainer}>
                  <Button
                    variant={isPowerBIActive ? 'headerActive' : 'header'}
                    active={isPowerBIActive}
                    onClick={handlePowerBIReportClick}
                    icon="reports"
                  >
                    <CustomTypography
                      variant="buttonText"
                      bold={isPowerBIActive}
                      color={isPowerBIActive ? 'accentDark' : 'greyscale1'}
                    >
                      {t(translations.header.powerBI)}
                    </CustomTypography>
                  </Button>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    variant={isNotificationsActive ? 'headerActive' : 'header'}
                    active={isNotificationsActive}
                    onClick={handleAllNotificationsClick}
                    icon="projects"
                  >
                    <CustomTypography
                      variant="buttonText"
                      bold={isNotificationsActive}
                      color={
                        isNotificationsActive ? 'accentDark' : 'greyscale1'
                      }
                    >
                      {t(translations.header.allNotifications)}
                    </CustomTypography>
                  </Button>
                </div>

                <div className={classes.buttonContainer}>
                  <Button
                    variant={isBatchesActive ? 'headerActive' : 'header'}
                    active={isBatchesActive}
                    onClick={handleBatchesClick}
                    icon="batches"
                  >
                    <CustomTypography
                      variant="buttonText"
                      bold={isBatchesActive}
                      color={isBatchesActive ? 'accentDark' : 'greyscale1'}
                    >
                      {t(translations.header.batches)}
                    </CustomTypography>
                  </Button>
                </div>

                {!isMember && (
                  <div className={classes.buttonContainer}>
                    <Button
                      variant={isTeammatesActive ? 'headerActive' : 'header'}
                      active={isTeammatesActive}
                      onClick={handleTeammatesClick}
                      icon="teammates"
                    >
                      <CustomTypography
                        variant="buttonText"
                        bold={isTeammatesActive}
                        color={isTeammatesActive ? 'accentDark' : 'greyscale1'}
                      >
                        {t(translations.header.teammates)}
                      </CustomTypography>
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>

          <div className={classes.sectionHeader}>
            <CustomTypography variant="bodyRegular" bold color="accentDark">
              {t(translations.header.profile)}
            </CustomTypography>
          </div>

          <div className={classes.sectionItems}>
            <div className={classes.buttonContainer}>
              <Button
                variant="header"
                onClick={handleProfileClick}
                icon="profile"
              >
                <CustomTypography variant="buttonText" color="greyscale1">
                  {t(translations.header.myProfile)}
                </CustomTypography>
              </Button>
            </div>

            {!isMember && (
              <div className={classes.buttonContainer}>
                <Button
                  variant="header"
                  onClick={handleMyOrganizationClick}
                  icon="myOrganization"
                >
                  <CustomTypography variant="buttonText" color="greyscale1">
                    {t(translations.header.myOrganization)}
                  </CustomTypography>
                </Button>
              </div>
            )}

            {isLeaveOrganizationVisible && (
              <div className={classes.buttonContainer}>
                <Button
                  variant="header"
                  onClick={handleLeaveOrganizationClick}
                  icon="allOrganizations"
                >
                  <CustomTypography variant="buttonText" color="greyscale1">
                    {t(translations.header.allOrganizations)}
                  </CustomTypography>
                </Button>
              </div>
            )}
            <div className={classes.buttonContainer}>
              <Button
                variant="header"
                onClick={handleLogoutClick}
                icon="logOut"
              >
                <CustomTypography variant="buttonText" color="greyscale1">
                  {t(translations.header.logOut)}
                </CustomTypography>
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
