import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Routes } from 'types';
import { NotificationEntityCardCard } from '../NotificationEntityCardCard';
import { useStyles } from './NotificationEntitySection.styles';
import { NotificationEntityCardListItem } from '../NotificationEntityCardListItem/NotificationEntityCardListItem';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/types';
import { SectionPagination } from '../SectionsPagination';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { firebaseAnalytics } from 'app/shared/analytics';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { getNotificationEntityName, useSearchHelper } from 'app/shared/utils';
import { ViewMode } from '../../types';
import { format, startOfDay } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useAuth } from 'app/shared/providers';
import { useNotificationEntityApi } from 'app/shared/hooks';

interface INotificationEntitySectionProps {
  view: ViewMode;
  refetchData: boolean;
  startDate?: string;
  endDate?: string;
  header?: string;
  userAssignedTickets?: boolean;
}

const cardViewPerPage = 8;
const listViewPerPage = 10;
const skipOnePage = 1;
const noElementsCount = 0;

export const NotificationEntitySection = ({
  view,
  refetchData,
  startDate,
  endDate,
  header,
  userAssignedTickets,
}: INotificationEntitySectionProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userId } = useAuth();

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const refToScroll = useRef<HTMLDivElement>(null);
  const { searchQuery, prepareSearchQueryParameter } = useSearchHelper();
  const {
    organizationId,
    entityType,
  }: { organizationId: string; entityType: string } = useParams();
  const { page, perPage } = useSelector(
    (state: RootState) => state.layout.notificationEntitySection,
  );
  const entityName = getNotificationEntityName(Number(entityType));

  const dateFilterFormat = useCallback((date: string | undefined | null) => {
    return date
      ? format(startOfDay(new Date(date)), "yyyy-MM-dd'T'HH:mm:ssxxx")
      : '';
  }, []);

  const paginationAndSearch = useMemo(() => {
    const params = {
      entityType: Number(entityType),
      organizationId: organizationId,
      queryParameters: {
        take: perPage,
        skip: `${(+page - skipOnePage) * +perPage}`,
        query: prepareSearchQueryParameter(searchQuery),
        startDateTime: startDate
          ? encodeURIComponent(dateFilterFormat(startDate))
          : '',
        endDateTime: endDate
          ? encodeURIComponent(dateFilterFormat(endDate))
          : !endDate && startDate
          ? encodeURIComponent(dateFilterFormat(startDate))
          : '',
        sortBy: 'lastTicketDispatchDateTimeUtc',
        sortAsc: false,
      },
    };

    if (userAssignedTickets) {
      //@ts-ignore
      params.queryParameters.userId = userId;
    }

    return params;
  }, [
    dateFilterFormat,
    endDate,
    organizationId,
    page,
    perPage,
    prepareSearchQueryParameter,
    searchQuery,
    startDate,
    entityType,
    userId,
    userAssignedTickets,
  ]);

  const {
    notificationEntityCards,
    fetchNotificationEntityCards,
    isLoadingNotificationEntityCards,
    isFetchingNotificationEntityCards,
    notificationEntityCardsMetadata,
  } = useNotificationEntityApi(paginationAndSearch);

  const scrollToComponent = useCallback(
    () => refToScroll?.current?.scrollIntoView(),
    [refToScroll],
  );

  const hasPagination = useMemo(() => {
    return (
      ((view === ViewMode.CARD_VIEW &&
        notificationEntityCardsMetadata &&
        notificationEntityCardsMetadata.totalCount > cardViewPerPage) ||
        (view === ViewMode.LIST_VIEW &&
          notificationEntityCardsMetadata &&
          notificationEntityCardsMetadata.totalCount > listViewPerPage)) &&
      isOpen
    );
  }, [isOpen, notificationEntityCardsMetadata, view]);

  useEffect(() => {
    fetchNotificationEntityCards();
  }, [fetchNotificationEntityCards, paginationAndSearch, refetchData]);

  const handleSpoilerClick = useCallback(() => {
    setIsOpen(prevState => !prevState);

    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPage,
      `${entityName} section spoiler`,
    );
  }, [entityName]);

  return (
    <div className={classes.wrapper}>
      <div ref={refToScroll} />

      <SectionSpoiler
        header={header || ` ${entityName}s`}
        margins={10}
        isFlex
        view={view}
        handleSpoilerClick={handleSpoilerClick}
      >
        {notificationEntityCards &&
          notificationEntityCards.map(notificationEntityCard => {
            return view === ViewMode.CARD_VIEW ? (
              <NotificationEntityCardCard
                key={notificationEntityCard.internalId}
                notificationEntityCard={notificationEntityCard}
              />
            ) : (
              <NotificationEntityCardListItem
                key={notificationEntityCard.internalId}
                notificationEntityCard={notificationEntityCard}
              />
            );
          })}
      </SectionSpoiler>

      {isOpen &&
        !notificationEntityCards?.length &&
        !isLoadingNotificationEntityCards && (
          <div className={classes.empty} data-testid="empty">
            <CustomTypography variant="bodyRegular" color="greyscale3">
              {`${t(
                translations.notificationEntityPage.thereAreCurrentlyNo,
              )} ${entityName}s`}
            </CustomTypography>
          </div>
        )}

      {(isLoadingNotificationEntityCards ||
        isFetchingNotificationEntityCards) && (
        <div
          className={
            isLoadingNotificationEntityCards
              ? classes.progressDefault
              : classes.progress
          }
        >
          <CircularProgress withLabel={true} />
        </div>
      )}

      {hasPagination && (
        <SectionPagination
          count={notificationEntityCardsMetadata?.totalCount || noElementsCount}
          onClick={scrollToComponent}
        />
      )}
    </div>
  );
};
