import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './ChartUnlimitedRange.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { ReactComponent as SlumpArrow } from 'assets/slump_arrow.svg';
import { themeColors } from 'app/shared/theme';
import { useProbeDataFormatter } from 'app/shared/hooks';
import { NotificationCategory } from 'types/general';

interface IChartUnlimitedRangeProps {
  chartLabel: string;
  coreValue: number;
  value: number;
  measurement?: string | null;
  iconType?: 'Triangle' | 'Circle';
  availableWidth: number;
  notificationCategory: NotificationCategory | null;
  chartWithSign: boolean;
}

const offset = 50;
const rangeWidth = 36.5;
const zero = 0;
const hundredPercent = 100;
const half = 2;
const defaultDecimalPlaces = 1;
const alternativeDecimalPlaces = 3;

const defaultValues = {
  measurement: null,
};

export const ChartUnlimitedRange = ({
  chartLabel,
  coreValue,
  value,
  measurement = defaultValues.measurement,
  iconType,
  availableWidth,
  notificationCategory,
  chartWithSign,
}: IChartUnlimitedRangeProps) => {
  const classes = useStyles();
  const [width, setWidth] = useState(zero);
  const { renderProbeData } = useProbeDataFormatter();

  const decimalPlaces = useMemo(() => {
    return notificationCategory === NotificationCategory.RETURNED_CONCRETE ||
      notificationCategory === NotificationCategory.MOISTURE ||
      notificationCategory == NotificationCategory.WATER_CEMENT_RATIO
      ? alternativeDecimalPlaces
      : defaultDecimalPlaces;
  }, [notificationCategory]);

  const valueContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setWidth(valueContainer.current?.offsetWidth || zero);
  }, [valueContainer]);

  const step = useMemo(() => rangeWidth / coreValue, [coreValue]);

  const targetValue = useMemo(() => {
    return value + coreValue;
  }, [coreValue, value]);

  const valueOffset = useMemo(() => {
    let result = 0;

    if (targetValue <= coreValue) {
      result = Math.max(
        offset - rangeWidth,
        offset - (coreValue - targetValue) * step,
      );
    } else if (targetValue > coreValue) {
      result = Math.min(rangeWidth, (targetValue - coreValue) * step) + offset;
    }

    return result;
  }, [targetValue, coreValue, step]);

  const recalculatedOffset = useMemo(() => {
    const valueContainerInPercents = (width / availableWidth) * hundredPercent;
    const sumOfOffsetAndValue = valueOffset + valueContainerInPercents;

    if (sumOfOffsetAndValue > hundredPercent) {
      return valueOffset - (sumOfOffsetAndValue - hundredPercent);
    } else {
      return valueOffset - valueContainerInPercents / half <= zero
        ? zero
        : valueOffset - valueContainerInPercents / half;
    }
  }, [availableWidth, valueOffset, width]);

  const offsetStyle = useMemo(
    () => ({
      marginLeft: `${recalculatedOffset}%`,
    }),
    [recalculatedOffset],
  );

  const Marker = () => {
    return (
      <div className={classes.markerContainer}>
        <div className={classes.marker} style={offsetStyle}>
          <div className={classes.value} ref={valueContainer}>
            <CustomTypography
              variant="caption1"
              bold
              color="systemRed"
              style={{ whiteSpace: 'nowrap', fontWeight: 700 }}
            >
              {`${value > zero && chartWithSign ? '+' : ''} ${renderProbeData(
                {
                  value,
                  measurement,
                },
                decimalPlaces,
                decimalPlaces === alternativeDecimalPlaces,
              )}`}
            </CustomTypography>
          </div>
          {iconType === 'Triangle' ? (
            <SlumpArrow fill={themeColors.systemRed} />
          ) : (
            <div className={classes.circleMarker} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.chart}>
      <div className={classes.chartTitle}>
        <CustomTypography variant="bodySmall" color="greyscale2">
          {chartLabel}
        </CustomTypography>
      </div>
      <Marker />
      <div className={classes.chartContainer}>
        <div className={classes.chartPart} />
      </div>
      <div className={classes.chartLabels}>
        <CustomTypography variant="bodySmall" color="greyscale2">
          {renderProbeData(
            {
              value: coreValue,
              measurement: measurement,
            },
            decimalPlaces,
            decimalPlaces === alternativeDecimalPlaces,
          )}
        </CustomTypography>
      </div>
    </div>
  );
};
