import React, { useCallback, useMemo, useRef, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import {
  ITeamAssignments,
  INotification,
  NotificationEntityType,
  IDeleteUserNotificationAssignmentsPayload,
  IGetUserNotificationAssignmentsPayload,
} from 'types';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import {
  CellAlignment,
  CellSize,
  PaginationType,
} from 'app/shared/components/generic-ui/Table/Table.types';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { getUserInitials, sortInAlphabeticalOrder } from 'app/shared/utils';
import { Avatar } from 'app/shared/components/generic-ui/Avatar/Avatar';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@material-ui/core';
import { useStyles } from './TeammatesList.styles';
import { useTeamAssignmentsList } from '../hooks';
import { BulkAssignButton } from '../components';
import { useNotificationSettings } from 'app/shared/providers';
import { CustomTypography } from '../../Typography/Typography';

interface ITeammatesList {
  teammates?: ITeamAssignments[];
  totalCount?: number;
  isLoading?: boolean;
  columnIds?: string[];
  notifications?: INotification[];
  selectedTeammates?: ITeamAssignments[];
  searchParams?: URLSearchParams;
  baseUrl: string;
  sortHandler?: (id: string) => void;
  changeUsersNotificationStatus: (
    users: {
      userId: string;
      notificationTypeId: string;
      isAssigned: boolean;
    }[],
  ) => void;
  paginationType?: PaginationType;
  removeAllOtherUserNotificationAssignments?: (
    payload: IDeleteUserNotificationAssignmentsPayload,
  ) => void;
  getUserNotificationAssignmentsCall?: (
    payload: IGetUserNotificationAssignmentsPayload,
    notification: INotification | undefined,
    teamAssignment: ITeamAssignments,
  ) => void;
}

const oneItem = 1;

export const TeammatesList = ({
  teammates,
  totalCount,
  isLoading,
  columnIds,
  changeUsersNotificationStatus,
  sortHandler,
  notifications,
  selectedTeammates,
  baseUrl,
  searchParams,
  paginationType = PaginationType.MAIN,
  removeAllOtherUserNotificationAssignments,
  getUserNotificationAssignmentsCall,
}: ITeammatesList) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>([]);
  const sort = new URLSearchParams(location.search).get('sort');
  const team = useMemo(() => {
    return selectedTeammates?.length
      ? sortInAlphabeticalOrder(
          sort || 'desc',
          selectedTeammates.map(
            item => teammates?.find(i => i.userId === item.userId) || item,
          ),
          'firstName',
          'lastName',
        )
      : teammates;
  }, [selectedTeammates, sort, teammates]);

  const { entityType } = useNotificationSettings();

  const {
    teamAssignments,
    changeSingleAssignmentHandler,
    changeMultipleAssignmentHandler,
    getCheckedState,
  } = useTeamAssignmentsList({
    teamAssignments: team,
    isResetting: isLoading,
  });

  const teamAssignmentsToChange = useMemo(() => {
    return selectedTeamIds.length
      ? teamAssignments?.filter(teamAssignment =>
          selectedTeamIds.find(el => el === teamAssignment.userId),
        )
      : teamAssignments;
  }, [selectedTeamIds, teamAssignments]);

  const submitSelectedNotificationsHandler = useCallback(
    notifications => {
      const ids = notifications.map((notification: INotification) => {
        return notification.notificationCode;
      });

      changeMultipleAssignmentHandler(true, ids, teamAssignmentsToChange);

      const userNotificationStatuses: {
        userId: string;
        notificationTypeId: string;
        isAssigned: boolean;
      }[] = [];
      notifications.forEach((notification: INotification) => {
        teamAssignmentsToChange?.forEach(teamAssignment => {
          userNotificationStatuses.push({
            userId: teamAssignment.userId,
            notificationTypeId: notification.notificationTypeId,
            isAssigned: true,
          });
        });
      });

      userNotificationStatuses &&
        changeUsersNotificationStatus(userNotificationStatuses);
    },
    [
      changeMultipleAssignmentHandler,
      changeUsersNotificationStatus,
      teamAssignmentsToChange,
    ],
  );

  const columns = useMemo(() => {
    const checkboxColumns = columnIds
      ? columnIds?.map((id, index) => {
          let chkEnabled = undefined;
          if (id != null) {
            const currentNotification = notifications?.find(
              notification => notification.notificationCode === id,
            );
            chkEnabled =
              entityType === NotificationEntityType.ORGANIZATION
                ? currentNotification?.active
                : currentNotification?.active &&
                  entityType === currentNotification.notificationEntityType;
          }
          return {
            key: index,
            id: id,
            label: '',
            alignment: CellAlignment.LEFT,
            size: CellSize.SMALL,
            sortable: false,
            maxWidth: false,
            buttons: [
              {
                button: (
                  <div
                    key={index}
                    className={
                      index === columnIds.length - oneItem
                        ? classes.checkboxHeaderVariant
                        : classes.checkboxHeader
                    }
                  >
                    {id ? (
                      <Checkbox
                        key={index}
                        disableRipple
                        size="small"
                        disabled={
                          chkEnabled !== undefined ? !chkEnabled : false
                        }
                        classes={{
                          colorSecondary: classes.icon,
                          checked: classes.checkedIcon,
                          disabled: classes.disabled,
                        }}
                        onChange={e => {
                          changeMultipleAssignmentHandler(
                            e.target.checked,
                            [id],
                            teamAssignmentsToChange,
                          );

                          const userNotificationStatuses =
                            teamAssignmentsToChange?.map(teamAssignment => {
                              return {
                                userId: teamAssignment.userId,
                                notificationTypeId:
                                  notifications?.find(
                                    notification =>
                                      notification.notificationCode === id,
                                  )?.notificationTypeId || '',
                                isAssigned: e.target.checked,
                              };
                            });

                          userNotificationStatuses &&
                            changeUsersNotificationStatus(
                              userNotificationStatuses,
                            );
                        }}
                        checked={getCheckedState(id, teamAssignmentsToChange)}
                      />
                    ) : null}
                  </div>
                ),
              },
            ],
          };
        })
      : [];

    const bulkAssignColumn = [
      {
        key: 16,
        id: 'bulkAssign',
        label: '',
        alignment: CellAlignment.RIGHT,
        size: CellSize.SMALL,
        sortable: false,
        maxWidth: false,
        buttons: [
          {
            button: (
              <BulkAssignButton
                disabled={!selectedTeamIds.length}
                notifications={notifications}
                submitSelectedNotifications={submitSelectedNotificationsHandler}
              />
            ),
          },
        ],
      },
    ];
    const defaultColumns = [
      {
        key: 17,
        id: 'name',
        label: t(translations.teammatesPage.name),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: true,
        maxWidth: true,
      },
    ]
      .concat(checkboxColumns)
      .concat(bulkAssignColumn);

    return defaultColumns;
  }, [
    changeMultipleAssignmentHandler,
    changeUsersNotificationStatus,
    classes.checkboxHeader,
    classes.checkboxHeaderVariant,
    classes.checkedIcon,
    classes.disabled,
    classes.icon,
    columnIds,
    getCheckedState,
    notifications,
    selectedTeamIds.length,
    submitSelectedNotificationsHandler,
    t,
    teamAssignmentsToChange,
    entityType,
  ]);

  const renderCell = useCallback(
    (rowData: ITeamAssignments, cellId: string) => {
      if (cellId === 'name') {
        const cellContent = rowData.isAdmin
          ? [rowData.firstName, rowData.lastName, '(Admin)']
          : [rowData.firstName, rowData.lastName];

        return (
          <div className={classes.info}>
            <Avatar
              content={
                cellContent[0] &&
                cellContent[1] &&
                getUserInitials(cellContent[0], cellContent[1])
              }
              color={'highlightBlue'}
              size={32}
            />
            <div className={classes.name}>
              <div>{cellContent.join(' ')}</div>
              <div className={classes.roles}>
                {rowData.roleNames?.join(', ')}
              </div>
            </div>
          </div>
        );
      } else if (cellId && cellId !== 'bulkAssign') {
        const currentNotification = notifications?.find(
          notification => notification.notificationCode === cellId,
        );
        const chkEnabled =
          entityType === NotificationEntityType.ORGANIZATION
            ? currentNotification?.active
            : currentNotification?.active &&
              entityType === currentNotification.notificationEntityType;

        const data = rowData.notifications?.find(
          el => el.notificationCode === cellId,
        );
        return (
          <div className={classes.checkboxCell}>
            <Checkbox
              disableRipple
              size="small"
              classes={{
                colorSecondary: classes.icon,
                checked: classes.checkedIcon,
                disabled: classes.disabled,
              }}
              disabled={!chkEnabled}
              onChange={e => {
                changeSingleAssignmentHandler(
                  e.target.checked,
                  cellId,
                  rowData,
                );
                changeUsersNotificationStatus([
                  {
                    userId: rowData.userId,
                    notificationTypeId:
                      notifications?.find(
                        notification =>
                          notification.notificationCode === cellId,
                      )?.notificationTypeId || '',
                    isAssigned: e.target.checked,
                  },
                ]);
              }}
              checked={data?.isAssigned}
            />
            {!!data?.hasOtherAssignments &&
              !!removeAllOtherUserNotificationAssignments &&
              !!getUserNotificationAssignmentsCall && (
                <Dots
                  isDisabled={!chkEnabled}
                  onView={() =>
                    getUserNotificationAssignmentsCall(
                      {
                        userId: rowData.userId,
                        notificationTypeId:
                          notifications?.find(
                            notification =>
                              notification.notificationCode === cellId,
                          )?.notificationTypeId || '',
                      },
                      currentNotification,
                      rowData,
                    )
                  }
                  onDelete={() => {
                    removeAllOtherUserNotificationAssignments({
                      userId: rowData.userId,
                      notificationTypeId:
                        notifications?.find(
                          notification =>
                            notification.notificationCode === cellId,
                        )?.notificationTypeId || '',
                    });
                  }}
                />
              )}
          </div>
        );
      }
    },
    [
      classes.info,
      classes.name,
      classes.roles,
      classes.checkboxCell,
      classes.icon,
      classes.checkedIcon,
      classes.disabled,
      notifications,
      entityType,
      removeAllOtherUserNotificationAssignments,
      getUserNotificationAssignmentsCall,
      changeSingleAssignmentHandler,
      changeUsersNotificationStatus,
    ],
  );

  return (
    <Table
      withHover={true}
      columns={columns}
      tableData={teamAssignments}
      withCheckbox={true}
      rowsTotal={totalCount}
      orderBy={'name'}
      rowId={'userId'}
      renderCell={renderCell}
      withPagination={!selectedTeammates?.length}
      sendSelectedItems={setSelectedTeamIds}
      baseURL={baseUrl}
      isLoading={isLoading}
      withPerPageCount={true}
      isScrollable={false}
      withPadding={false}
      withHeaderPadding={false}
      sortHandler={sortHandler}
      minHeight={355}
      searchParams={searchParams}
      paginationType={paginationType}
      minWidth={1320}
      overflowX="hidden"
    />
  );
};

interface IDotsProps {
  onDelete: () => void;
  onView: () => void;
  isDisabled: boolean;
}

function Dots({ onDelete, onView, isDisabled }: IDotsProps) {
  const classes = useStyles();
  const iconButtonRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={`${classes.dotWrapper} ${
          isDisabled ? classes.dotWrapperDisabled : ''
        }`}
        ref={iconButtonRef}
        onClick={isDisabled ? undefined : handleOpen}
      >
        <MoreVertIcon
          classes={{
            root: isDisabled ? classes.dotIconDisabled : classes.dotIcon,
          }}
        />
      </div>
      <Popper
        open={isOpen}
        anchorEl={iconButtonRef.current}
        transition
        disablePortal
        placement="bottom-start"
        className={classes.zIndex}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.wrapper}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <Button
                    className={classes.button}
                    onClick={() => {
                      onDelete();
                      handleClose();
                    }}
                  >
                    <div className={classes.label}>
                      <CustomTypography variant="buttonText" color="accentDark">
                        {t(translations.teammatesPage.remove)}
                      </CustomTypography>
                    </div>
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => {
                      onView();
                      handleClose();
                    }}
                  >
                    <div className={classes.label}>
                      <CustomTypography variant="buttonText" color="accentDark">
                        {t(translations.teammatesPage.view)}
                      </CustomTypography>
                    </div>
                  </Button>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
