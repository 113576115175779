import { useEffect, useState } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { useParams } from 'react-router-dom';
import { usePowerBIReport } from 'app/shared/hooks/usePowerBIReport';

function PowerBiPOC() {
  const { organizationId }: { organizationId: string } = useParams();
  const [reportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>();

  const { data } = usePowerBIReport({ organizationId });

  useEffect(() => {
    if (data) {
      setReportConfig({
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: data.embedToken.token,
        embedUrl: data.embedReport[0].embedUrl,
        id: data.embedReport[0].reportId,
      });
    }
  }, [data]);

  return (
    <div>
      {reportConfig ? (
        <PowerBIEmbed
          embedConfig={reportConfig}
          cssClassName="power-bi-report-class"
        />
      ) : (
        <div
          style={{
            height: 'calc(100vh - 87px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress withLabel />
        </div>
      )}
    </div>
  );
}
export default PowerBiPOC;
