import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '-2px',
    height: 36,
    backgroundColor: themeColors.white,
  },
  tab: {
    minWidth: 155,
    maxWidth: 184,
    paddingTop: 9,
    paddingBottom: 9,
    marginLeft: 1,
    backgroundColor: themeColors.greyscale4,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  activeTab: {
    minWidth: 155,
    maxWidth: 184,
    paddingTop: 9,
    paddingBottom: 9,
    marginLeft: 1,
    backgroundColor: themeColors.white,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});
